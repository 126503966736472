<template>
    <div style="background: #fff;">
        <!-- 基本信息 -->
        <div class="more_top">
            <el-button
                :disabled="$store.getters.permissionsArr(4)"
                @click="
                    $router.push({
                        path: `/management/architectureOperation?uuid=${$route.params.uuid}&currentPage=${$route.query.currentPage}`,
                    })
                "
                class="deit_btn"
                style="background: #1A9F16; color: #fff; margin-right: 10px;"
                round
            >
                <span>编辑</span>
            </el-button>

            <!-- <span class="fanhui" @click="() => {$router.push({path: '/management/architecture'})}"><i class="iconfont iconfanhui"></i>返回</span> -->
            <span
                class="fanhui"
                @click="() => { $router.push({ path: `/management/architecture?currentPage=${$route.query.currentPage}` }) }"
            >
                <i class="iconfont iconfanhui"></i>返回
            </span>
            <!-- <el-button icon="iconfont icon-daochu-icon" style="margin-right:4px;" plain class="redColor">
                <span class="buttonText">导出</span>
            </el-button>
            <el-button icon="iconfont icon-dayin-icon" plain class="greenColor">
                <span class="buttonText">打印</span>
            </el-button>-->
        </div>
        <div class="content" v-loading="Loading">
            <div class="left-head-img" v-if="userData">
                <img
                    :src="
                        userData.head_img
                            ? `http://${userData.head_img}`
                            : headImg
                    "
                    alt
                    height="130"
                    width="130"
                />
            </div>
            <div class="right-content clearfix" v-if="userData">
                <div class="right_content_top">
                    <p>
                        <i class="iconfont icon-zuzhijiagou-iconsvg yellowColor"></i>
                        <span style="margin-left:6px;">
                            <b></b> 基础信息
                        </span>
                    </p>
                    <div class="item_con">
                        <div class="item clearfix">
                            <div></div>
                            <div>姓名 ： {{ userData.name }}</div>
                        </div>
                        <div class="item clearfix">
                            <div></div>
                            <div>性别 ： {{ userData.sex === 1 ? '男' : '女' }}</div>
                        </div>
                        <!-- <div class="item clearfix">
                            <div></div>
                            <div>学号/编号 ： {{ userData.code }}</div>
                        </div> -->
                        <!--        h         <div class="item clearfix">
                                    <div></div>
                                    <div>年龄 ： {{userData.age}}</div>
                                </div>
                        -->
                        <div class="item clearfix">
                            <div></div>
                            <div>出生年月 ： {{ userData.birthdays }}</div>
                        </div>
                        <div class="item clearfix">
                            <div></div>
                            <div>
                                {{
                                    userData.identity_type === 1
                                        ? '身份证'
                                        : userData.identity_type === 2
                                            ? '护照'
                                            : '证件号'
                                }}
                                ：
                                {{ userData.identity_number }}
                            </div>
                        </div>
                        <div class="item clearfix">
                            <div></div>
                            <div>岗位 ： {{ userData.station_name }}</div>
                        </div>
												<div class="item clearfix" v-if="userData.is_emphasis">
                            <div></div>
                            <div>是否为重点运动员 ： {{ userData.is_emphasis == 1 ? '非重点' : userData.is_emphasis == 2 ? '重点' : ''}}</div>
                        </div>
                        <div class="item clearfix">
                            <div></div>
                            <div>
                                学历 ：{{ userData.education }} {{
                                    userData.education == 1 ?
                                        '博士' : userData.education == 2 ?
                                            '硕士' : userData.education == 3 ?
                                                '本科' : userData.education == 4 ?
                                                    '专科' : userData.education == 5 ?
                                                        '其他' : ''
                                }}
                            </div>
                        </div>
                        <div class="item clearfix">
                            <div></div>
                            <div>民族 ： {{ userData.nation }}</div>
                        </div>
                        <div class="item clearfix">
                            <div></div>
                            <div>户籍 ： {{ userData.native_place }}</div>
                        </div>
                        <div class="item clearfix">
                            <div></div>
                            <el-tooltip
                                :content="userData.department_name"
                                effect="dark"
                                placement="top"
                                style="float: left;"
                                v-show="
                                    userData.department_name.length >
                                    (!$store.state.equipment ? 10 : 15)
                                "
                            >
                                <div
                                    v-show="
                                        userData.department_name.length <
                                        (!$store.state.equipment ? 10 : 15)
                                    "
                                >
                                    所属运动队 ：
                                    {{
                                        userData.department_name.slice(
                                            0,
                                            !$store.state.equipment ? 10 : 15
                                        ) + '...'
                                    }}
                                </div>
                            </el-tooltip>
                            <div
                                style="font-weight:400;"
                                v-show="
                                    userData.department_name.length <
                                    (!$store.state.equipment ? 10 : 15)
                                "
                            >所属运动队 ： {{ userData.department_name }}</div>
                        </div>
                        <div class="item clearfix" v-show="userData.station_name === '运动员'">
                            <div></div>
                            <div>项目 ： {{ userData.sport_name }}</div>
                        </div>
                        <div class="item clearfix" v-show="userData.station_name === '运动员'">
                            <div></div>
                            <el-tooltip
                                :content="userData.sport_event_name"
                                effect="dark"
                                placement="top"
                                style="float: left;"
                                v-show="
                                    userData.sport_event_name.length >=
                                    (!$store.state.equipment ? 10 : 20)
                                "
                            >
                                <div
                                    v-show="
                                        userData.sport_event_name.length <=
                                        (!$store.state.equipment ? 10 : 20)
                                    "
                                >
                                    专项 ：
                                    {{
                                        userData.sport_event_name.slice(
                                            0,
                                            !$store.state.equipment ? 10 : 20
                                        ) + '...'
                                    }}
                                </div>
                            </el-tooltip>
                            <div
                                style="font-weight:400;"
                                v-show="
                                    userData.sport_event_name.length <
                                    (!$store.state.equipment ? 10 : 20)
                                "
                            >专项 ： {{ userData.sport_event_name }}</div>
                        </div>
                        <div class="item clearfix" v-show="userData.station_name === '运动员'">
                            <div></div>
                            <div class="_yic">教练 ： {{ userData.coach_name }}</div>
                        </div>
                        <div class="item clearfix" v-show="userData.station_name === '运动员'">
                            <div></div>
                            <div>身高 ： {{ userData.height == '' ? '' : userData.height + 'cm' }}</div>
                        </div>
                        <div class="item clearfix" v-show="userData.station_name === '运动员'">
                            <div></div>
                            <div>体重 ： {{ userData.weight == '' ? '' : userData.weight + 'kg' }}</div>
                        </div>
                        <div class="item clearfix" v-show="userData.station_name === '运动员'">
                            <div></div>
                            <div>运动年限 ： {{ userData.sport_year }}</div>
                        </div>
                        <div class="item clearfix" v-show="userData.station_name === '运动员'">
                            <div></div>
                            <div>入队时间 ： {{ userData.in_time }}</div>
                        </div>
                        <div class="item clearfix" v-show="userData.station_name === '运动员'">
                            <div></div>
                            <div>
                                级别 ：
                                {{
                                    userData && userData.grade && levelOptions.options[Number(userData.grade) - 1].label
                                }}
                            </div>
                        </div>
                        <div class="item clearfix" v-show="userData.station_name === '运动员'">
                            <div></div>
                            <el-tooltip
                                :content="userData.permanent_address"
                                effect="dark"
                                placement="top"
                                style="float: left;"
                                v-show="
                                    userData.permanent_address.length >
                                    (!$store.state.equipment ? 8 : 16)
                                "
                            >
                                <div
                                    v-show="
                                        userData.permanent_address.length <
                                        (!$store.state.equipment ? 8 : 16)
                                    "
                                >
                                    常驻地址 ：
                                    {{
                                        userData.permanent_address.slice(
                                            0,
                                            !$store.state.equipment ? 8 : 16
                                        ) + '...'
                                    }}
                                </div>
                            </el-tooltip>
                            <div
                                style="font-weight:400;"
                                v-show="
                                    userData.permanent_address.length <
                                    (!$store.state.equipment ? 8 : 16)
                                "
                            >常驻地址 ： {{ userData.permanent_address }}</div>
                        </div>
                        <div class="item clearfix" v-show="userData.station_name === '运动员'">
                            <div></div>
                            <div>设备登录号 ： {{ userData.id }}</div>
                        </div>
                        <!-- <div class="item clearfix">
                    <div></div>
                    <div>学历 ： {{ userData.education }}</div>
                        </div>-->
                        <!-- <div class="item clearfix" v-show="userData.station_name === '运动员'">
                    <div></div>
                    <div>教练 ： {{ userData.coach_name }}</div>
                        </div>-->
                        <!--                 <div class="item clearfix">
                                    <div></div>
                                    <div>设备登陆号 ： {{userData.id}}</div>
                                </div>
                        -->
                        <!-- 
                
                
                        -->
                        <!--  -->
                        <!-- 
                        -->
                        <!-- <div class="item clearfix" v-show="userData.station_name === '运动员'">
                    <div></div>
                    <el-tooltip :content="userData.permanent_address" effect="dark" placement="top" style="float: left;" v-show="
              userData.permanent_address.length >
                (!$store.state.equipment ? 8 : 16)
            ">
                        <div v-show="
                userData.permanent_address.length <
                  (!$store.state.equipment ? 8 : 16)
              ">
                            常驻地址 ：
                            {{
                            userData.permanent_address.slice(
                            0,
                            !$store.state.equipment ? 8 : 16
                            ) + '...'
                            }}
                        </div>
                    </el-tooltip>
                    <div style="font-weight:400;" v-show="
              userData.permanent_address.length <
                (!$store.state.equipment ? 8 : 16)
            ">
                        常驻地址 ： {{ userData.permanent_address }}
                    </div>
                </div>
                        -->
                        <!-- <div class="item clearfix" v-show="userData.station_name === '运动员'">
                    <div></div>
                    <div>就读年级 ： {{userData.study_grade }}</div>
                        </div>-->
                        <!-- <div class="item clearfix" v-show="userData.station_name === '运动员'">
                    <div></div>
                    <div>培训时间 ： {{ userData.train_date }}</div>
                        </div>-->
                        <!-- <div class="item clearfix" v-show="userData.station_name === '运动员'">
                    <div></div>
                    <div>输送区县 ： {{ userData.transport_area }}</div>
                        </div>-->
                    </div>
                </div>
                <div class="right_content_bot">
                    <p class="clearfix" style="overflow:hidden;">
                        <i class="iconfont icon-lianxifangshi-icon yellowColor"></i>
                        <span style="margin-left:6px;">
                            <b></b>联系方式
                        </span>
                    </p>
                    <div class="item_con">
                        <div class="item clearfix">
                            <div></div>
                            <div>联系方式 ： {{ userData.mobile }}</div>
                        </div>
                        <div class="item clearfix" style="width: 60%;">
                            <div></div>
                            <div>邮箱 ： {{ userData.email }}</div>
                        </div>
                        <div :key="index" v-for="(item, index) in userData.emergency_con">
                            <div class="item clearfix">
                                <div></div>
                                <div>联系人 ： {{ item.emergency_name }}</div>
                            </div>
                            <div class="item clearfix">
                                <div></div>
                                <div>
                                    联系人关系 ：
                                    {{
                                        item.emergency_relation
                                            ? bondArr[Number(item.emergency_relation) - 1]
                                            : ''
                                    }}
                                </div>
                            </div>
                            <div class="item clearfix">
                                <div></div>
                                <div>联系人电话 ： {{ item.emergency_contact }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
            :close-on-click-modal="false"
            :visible.sync="addUserDialog"
            :width="'50%'"
            class="clearfix"
            title
        >
            <div class="clearfix dialog-wrap" style="margin-top:-10px;">
                <div style="text-align:center;">
                    <h4>基础信息</h4>
                    <img
                        :src="imgData == null ? '../../../static/logo.png' : imgData"
                        alt
                        height="150"
                        style="border-radius:50%;"
                        width="150"
                    />
                    <div class="upload-wrap">
                        <span>上传头像</span>
                        <input
                            @change="changeFile($event)"
                            accept="image/png, image/gif, image/jpg"
                            type="file"
                        />
                    </div>
                    <p>格式：支持jpg、png</p>
                    <el-button @click="dialogNext">下一步</el-button>
                </div>
                <div>
                    <el-form
                        :inline-message="false"
                        :label-position="'left'"
                        :model="ruleForm"
                        :rules="rules"
                        class="demo-ruleForm"
                        label-width="100px"
                        ref="ruleForm"
                    >
                        <el-form-item label="姓名" prop="name">
                            <el-input placeholder="请输入2-10字名字" v-model="ruleForm.name"></el-input>
                        </el-form-item>
                        <el-form-item label="性别" prop="sex">
                            <el-select
                                placeholder="请选择性别"
                                style="width:100%;"
                                v-model="ruleForm.sex"
                            >
                                <el-option
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                    v-for="item in sexOptions.options"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="出生年月" prop="birthday">
                            <el-date-picker
                                :editable="false"
                                placeholder="选择生日"
                                style="width:100%;"
                                type="date"
                                v-model="ruleForm.birthday"
                                value-format="yyyy-MM-dd"
                            ></el-date-picker>
                        </el-form-item>
                        <el-form-item label="身高" prop="height">
                            <el-input
                                :maxlength="3"
                                placeholder="请输入身高"
                                v-model.number="ruleForm.height"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="体重" prop="weight">
                            <el-input
                                type="number"
                                :maxlength="3"
                                placeholder="请输入体重"
                                v-model="ruleForm.weight"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="民族" prop="nation">
                            <el-input placeholder="请输入民族" v-model="ruleForm.nation"></el-input>
                        </el-form-item>
                        <el-form-item label="常驻地址" prop="permanent_address">
                            <el-input placeholder="请输入常驻地址" v-model="ruleForm.permanent_address"></el-input>
                        </el-form-item>
                        <el-form-item label="所属运动队" prop="department_uuid">
                            <el-cascader
                                :options="$store.state.treeMenu"
                                :props="{
                                    label: 'name',
                                    value: 'uuid',
                                    multiple: true,
                                }"
                                placeholder="请选择所属运动队"
                                style="width:100%;"
                                v-model="ruleForm.department_uuid"
                            ></el-cascader>
                        </el-form-item>
                        <el-form-item label="岗位" prop="station_uuid">
                            <el-select
                                placeholder="请选择岗位"
                                style="width:100%;"
                                v-model="ruleForm.station_uuid"
                            >
                                <el-option
                                    :key="item.uuid"
                                    :label="item.name"
                                    :value="item.uuid"
                                    v-for="item in jobsOptions.options"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="级别" prop="grade">
                            <el-select
                                placeholder="请选择级别"
                                style="width:100%;"
                                v-model="ruleForm.grade"
                            >
                                <el-option
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                    v-for="item in levelOptions.options"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            :prop="
                                ruleForm.station_uuid == 'C7258416-178A-64EA-523E-AADE09733C55'
                                    ? 'coach_name'
                                    : ''
                            "
                            label="教练"
                        >
                            <el-input placeholder="请输入教练姓名" v-model="ruleForm.coach_name"></el-input>
                        </el-form-item>
                        <el-form-item
                            :prop="
                                ruleForm.station_uuid == 'C7258416-178A-64EA-523E-AADE09733C55'
                                    ? 'sport_uuid'
                                    : ''
                            "
                            label="运动类"
                        >
                            <el-select
                                @change="selectTypeArr"
                                placeholder="请选择运动类"
                                style="width:100%;"
                                v-model="ruleForm.sport_uuid"
                            >
                                <el-option
                                    :key="item.uuid"
                                    :label="item.name"
                                    :value="item.uuid"
                                    v-for="item in typeOptions.options"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            :prop="
                                ruleForm.station_uuid == 'C7258416-178A-64EA-523E-AADE09733C55'
                                    ? 'sport_event_uuid'
                                    : ''
                            "
                            label="运动项"
                        >
                            <el-select
                                multiple
                                placeholder="请选择运动项"
                                style="width:100%;"
                                v-model="ruleForm.sport_event_uuid"
                            >
                                <el-option
                                    :key="item.name"
                                    :label="item.name"
                                    :value="item.uuid"
                                    v-for="item in projectOptions.options"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            :prop="
                                ruleForm.station_uuid == 'C7258416-178A-64EA-523E-AADE09733C55'
                                    ? 'sport_year'
                                    : ''
                            "
                            label="运动年限"
                        >
                            <el-input placeholder="请输入运动年限" v-model="ruleForm.sport_year"></el-input>
                        </el-form-item>
                        <el-form-item
                            :prop="
                                ruleForm.station_uuid == 'C7258416-178A-64EA-523E-AADE09733C55'
                                    ? 'in_time'
                                    : ''
                            "
                            label="入队时间"
                        >
                            <el-date-picker
                                :editable="false"
                                placeholder="选择入队时间"
                                style="width:100%;"
                                type="date"
                                v-model="ruleForm.in_time"
                                value-format="yyyy-MM-dd"
                            ></el-date-picker>
                        </el-form-item>
                        <el-form-item label="户籍" prop="native_place">
                            <el-input placeholder="请输入户籍" v-model="ruleForm.native_place"></el-input>
                        </el-form-item>
                        <el-form-item label="证件号" prop="identity_number">
                            <el-input
                                class="input-with-select"
                                placeholder="请输入证件号"
                                style="background:#fff;"
                                v-model="ruleForm.identity_number"
                            >
                                <el-select
                                    placeholder="类型"
                                    slot="prepend"
                                    style="width:80px;"
                                    v-model="ruleForm.identity_type"
                                >
                                    <el-option :value="1" label="身份证"></el-option>
                                    <el-option :value="2" label="护照"></el-option>
                                </el-select>
                            </el-input>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="addUserDialogStep2" :width="'50%'" class="clearfix" title>
            <div :close-on-click-modal="false" class="clearfix dialog-wrap">
                <div style="text-align:center;">
                    <h4>联系方式</h4>
                    <img
                        :src="imgData == null ? '../../../static/logo.png' : imgData"
                        alt
                        height="150"
                        style="border-radius:50%;"
                        width="150"
                    />
                    <div class="upload-wrap">
                        <span>上传头像</span>
                        <input type="file" />
                    </div>
                    <p>格式：支持jpg、png</p>
                    <el-button @click="(addUserDialogStep2 = false), (addUserDialog = true)">上一步</el-button>
                </div>
                <div>
                    <el-form
                        :label-position="'left'"
                        :model="ruleFormNext"
                        :rules="rules"
                        class="demo-ruleForm"
                        label-width="100px"
                        ref="ruleFormNext"
                    >
                        <el-form-item label="手机号" prop="mobile">
                            <el-input placeholder="请输入手机号" v-model="ruleFormNext.mobile"></el-input>
                        </el-form-item>
                        <el-form-item label="邮箱" prop="email">
                            <el-input placeholder="请输入邮箱" v-model="ruleFormNext.email"></el-input>
                        </el-form-item>
                        <el-form-item label="紧急联系人1" prop="emergency_name1">
                            <el-input placeholder="请输入紧急联系人" v-model="ruleFormNext.emergency_name1"></el-input>
                        </el-form-item>
                        <el-form-item label="关系" prop="emergency_relation1">
                            <el-select
                                placeholder="请选择关系"
                                style="width:100%;"
                                v-model="ruleFormNext.emergency_relation1"
                            >
                                <el-option
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                    v-for="item in between.options"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="联系方式" prop="emergency_contact1">
                            <el-input
                                placeholder="请输入联系方式"
                                v-model="ruleFormNext.emergency_contact1"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="紧急联系人2">
                            <el-input placeholder="请输入紧急联系人" v-model="ruleFormNext.emergency_name2"></el-input>
                        </el-form-item>
                        <el-form-item label="关系">
                            <el-select
                                placeholder="请选择关系"
                                style="width:100%;"
                                v-model="ruleFormNext.emergency_relation2"
                            >
                                <el-option
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                    v-for="item in between.options"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="联系方式">
                            <el-input
                                placeholder="请输入联系方式"
                                v-model="ruleFormNext.emergency_contact2"
                            ></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button @click="save" type="primary" v-no-more-click>保存</el-button>
                            <el-button @click="addUserDialogStep2 = false">取消</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import img from './../../../../static/head-default-big.jpg';

let data = {
    name: '', //姓名
    sex: '', //性别
    birthday: '',
    height: '',
    weight: '',
    nation: '', //民族
    department_uuid: '', //运动队
    station_uuid: '', //岗位
    grade: '', //级别
    sport_uuid: '', //运动类
    sport_event_uuid: [], //运动项
    in_time: '', //入队时间
    native_place: '', //籍贯
    identity_type: '', //证件类型
    identity_number: '', //证件号
    coach_name: '', //教练
    sport_year: '', //年限
    permanent_address: '', //常驻地址
};

let dataNext = {
    mobile: '', //手机号
    email: '', //邮箱
    emergency_name1: '', //紧急联系人1名称
    emergency_relation1: '', //紧急联系人1关系
    emergency_contact1: '', //紧急联系人1手机号
    emergency_name2: '',
    emergency_relation2: '',
    emergency_contact2: '',
};

export default {
    data() {
        return {
            Loading:true,
            headImg: img,
            activeName: '',
            bondArr: ['父亲', '母亲', '兄弟姐妹', '配偶', '子女', '其他'],
            userData: null,
            sexOptions: {
                options: [{
                    value: 1,
                    label: '男',
                },
                {
                    value: 2,
                    label: '女',
                },
                ],
                value: '',
            },
            departmentOptions: {
                options: [],
            },
            jobsOptions: {
                options: [],
            },
            levelOptions: {
                options: [{
                    value: 1,
                    label: '国际健将',
                },
                {
                    value: 2,
                    label: '运动健将',
                },
                {
                    value: 3,
                    label: '一级',
                },
                {
                    value: 4,
                    label: '二级',
                },
                {
                    value: 5,
                    label: '三级',
                },
                {
                    value: 6,
                    label: '无',
                },
                ],
            },
            between: {
                options: [{
                    value: 1,
                    label: '父亲',
                },
                {
                    value: 2,
                    label: '母亲',
                },
                {
                    value: 3,
                    label: '兄弟姐妹',
                },
                {
                    value: 4,
                    label: '配偶',
                },
                {
                    value: 5,
                    label: '子女',
                },
                {
                    value: 6,
                    label: '其他',
                },
                ],
            },
            typeOptions: {
                options: [],
            },
            projectOptions: {
                options: [],
            },
            lationshipOptions: {
                options: [],
            },

            ruleForm: {
                ...data,
            },
            ruleFormNext: {
                ...dataNext,
            },
            rules: {
                name: this.$rules.name,
                id: { ...this.$rules.noEmpty, message: 'id不能为空' },
                sex: { ...this.$rules.noEmpty, message: '性别不能为空' },
                age: { ...this.$rules.noEmpty, message: '年龄不能为空' },
                department_uuid: {
                    ...this.$rules.noEmpty,
                    message: '所属运动队不能为空',
                },
                mobile: this.$rules.phone,
                permanent_address: {
                    ...this.$rules.noEmpty,
                    message: '常驻不能为空',
                },
                station_uuid: {
                    ...this.$rules.noEmpty,
                    message: '岗位不能为空',
                },
                sport_uuid: {
                    ...this.$rules.noEmpty,
                    message: '运动类不能为空',
                },
                sport_event_uuid: {
                    ...this.$rules.noEmpty,
                    message: '运动项不能为空',
                },
                birthday: {
                    ...this.$rules.noEmpty,
                    message: '出生日期不能为空',
                },
                height: { ...this.$rules.isNumber, message: '身高格式不正确' },
                weight: { ...this.$rules.isNumber, message: '体重格式不正确' },
                grade: { ...this.$rules.noEmpty, message: '民族不能为空' },
                level: { ...this.$rules.noEmpty, message: '级别不能为空' },
                in_time: {
                    ...this.$rules.noEmpty,
                    message: '入队时间不能为空',
                },
                nation: { ...this.$rules.noEmpty, message: '民族不能为空' },
                native_place: {
                    ...this.$rules.noEmpty,
                    message: '户籍不能为空',
                },
                email: this.$rules.email,
                emergency_name1: {
                    ...this.$rules.noEmpty,
                    message: '紧急联系人不能为空',
                },
                emergency_relation1: {
                    ...this.$rules.noEmpty,
                    message: '联系人关系不能为空',
                },
                emergency_contact1: this.$rules.phone,
                emergency_name2: {
                    ...this.$rules.noEmpty,
                    message: '紧急联系人不能为空',
                },
                emergency_relation2: {
                    ...this.$rules.noEmpty,
                    message: '联系人关系不能为空',
                },
                emergency_contact2: this.$rules.phone,
                coach_name: {
                    ...this.$rules.noEmpty,
                    message: '教练名称不能为空',
                },
                sport_year: {
                    ...this.$rules.noEmpty,
                    message: '运动年限不能为空',
                },
                identity_number: {
                    ...this.$rules.noEmpty,
                    message: '证件号不能为空',
                },
            },
            addUserDialogStep2: false,
            imgData: null,
            tableDataSelectArr: [],
            imgFile: null,
            addUserDialog: false,
        };
    },
    created() {
        this.getData();
        this.getSelectData();
        this.$store.state.breadcrumbArr = ['人事管理', '组织结构', '电子档案'];
    },
    methods: {
        addUserDialogClick() {
            this.addUserDialog = true;
            this.typeOptions.options.forEach((i, k) => {
                if (i.uuid == this.ruleForm.sport_uuid) {
                    this.projectOptions.options = i.children;
                }
            });
            setTimeout(() => {
                this.$dialogHeight();
            }, 0);
        },

        getData() {
            this.$axios
                .post(
                    '/p/staff/info',
                    this.$qs({
                        uuid: this.$route.params.uuid,
                    })
                )
                .then((res) => {
                    if (res.data.code == 0) {
                        switch (res.data.data[0].education) {
                            case 1:
                                res.data.data[0].education = '博士';
                                break;
                            case 2:
                                res.data.data[0].education = ' 硕士';
                                break;
                            case 3:
                                res.data.data[0].education = '本科';
                                break;
                            case 4:
                                res.data.data[0].education = '专科';
                                break;
                            case 5:
                                res.data.data[0].education = '其他';
                                break;
                        }
                        this.userData = res.data.data[0];
                        this.imgData = `${this.$store.state.url}/${res.data.data[0].head_img}`;
                        this.ruleForm = {
                            ...res.data.data[0],
                            birthday: res.data.data[0].birthdays,
                            sport_event_uuid: res.data.data[0].sport_event_uuid != '' ?
                                res.data.data[0].sport_event_uuid.split(',') :
                                [],
                        };
                        this.ruleFormNext = { ...res.data.data[0] };
                        this.Loading = false
                    } else {
                        this.$message({
                            type: "warning",
                            message: res.data.msg.message
                        });
                    }
                });
        },
        changeFile(e) {
            function getObjectURL(file) {
                var url = null;
                if (window.createObjectURL != undefined) {
                    // basic
                    url = window.createObjectURL(file);
                } else if (window.URL != undefined) {
                    // mozilla(firefox)
                    url = window.URL.createObjectURL(file);
                } else if (window.webkitURL != undefined) {
                    // webkit or chrome
                    url = window.webkitURL.createObjectURL(file);
                }
                return url;
            }

            let imgData = e.target.files[0];
            this.imgFile = imgData;
            this.imgData = getObjectURL(imgData);
        },
        /*弹出框，下一步*/
        dialogNext() {
            let submit = () => {
                this.addUserDialog = false;
                this.addUserDialogStep2 = true;
            };
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    submit();
                } else {
                    return false;
                }
            });
        },
        save() {
            let submit = () => {
                let department_uuid = [];
                this.ruleForm.department_uuid.forEach((i, k) => {
                    department_uuid.push(i[i.length - 1]);
                });
                let data = {
                    name: this.ruleForm.name,
                    sex: this.ruleForm.sex,
                    birthday: this.ruleForm.birthday,
                    height: this.ruleForm.height,
                    weight: this.ruleForm.weight,
                    nation: this.ruleForm.nation,
                    station_uuid: this.ruleForm.station_uuid,
                    grade: this.ruleForm.grade,
                    coach_name: this.ruleForm.coach_name,
                    sport_year: this.ruleForm.sport_year,
                    in_time: this.ruleForm.in_time,
                    native_place: this.ruleForm.native_place,
                    identity_type: this.ruleForm.identity_type,
                    identity_number: this.ruleForm.identity_number,
                    permanent_address: this.ruleForm.permanent_address,
                    mobile: this.ruleFormNext.mobile,
                    email: this.ruleFormNext.email,
                    emergency_name1: this.ruleFormNext.emergency_name1,
                    emergency_relation1: this.ruleFormNext.emergency_relation1,
                    emergency_contact1: this.ruleFormNext.emergency_contact1,
                    emergency_name2: this.ruleFormNext.emergency_name2,
                    emergency_relation2: this.ruleFormNext.emergency_relation2,
                    emergency_contact2: this.ruleFormNext.emergency_contact2,
                    uuid: this.$route.params.uuid,
                    sport_uuid: this.ruleForm.sport_uuid,
                    department_uuid: department_uuid.join(','),
                    sport_event_uuid: this.ruleForm.sport_event_uuid.join(','),
                };
                if (
                    this.ruleForm.station_uuid != 'C7258416-178A-64EA-523E-AADE09733C55'
                ) {
                    delete data['coach_name'];
                    delete data['sport_uuid'];
                    delete data['sport_event_uuid'];
                    delete data['sport_year'];
                    delete data['in_time'];
                }
                for (let i in data) {
                    if (data[i] == '') {
                        delete data[i];
                    }
                }

                let formData = new FormData();
                if (this.imgFile) {
                    formData.append('head_img', this.imgFile);
                }
                for (let i in data) {
                    formData.append(i, data[i]);
                }
                this.$axios
                    .post('/p/staff/update', formData)
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.$refs.ruleFormNext.resetFields();
                            this.getData();
                            this.addUserDialogStep2 = false;
                            this.$utils.message();
                        } else {
                            this.$utils.message({
                                type: 'error',
                                message: res.data.message,
                            });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            };
            this.$refs['ruleFormNext'].validate((valid) => {
                if (valid) {
                    submit();
                } else {
                    return false;
                }
            });
        },
        selectTypeArr(val) {
            this.typeOptions.options.forEach((i, k) => {
                if (i.uuid == val) {
                    this.projectOptions.options = i.children;
                }
            });
            this.ruleForm.sport_event_uuid = [];
            this.selectArr.project = '';
        },

        getSelectData() {
            this.$axios
                .all([
                    this.$axios.post('/p/staff/sport'),
                    this.$axios.post('/p/staff/tree'),
                    this.$axios.post('/p/station/show'),
                ])
                .then(
                    this.$axios.spread((res1, res2, res3) => {
                        this.departmentOptions.options = res2.data.data.department;
                        this.typeOptions.options = res1.data.data;
                        this.jobsOptions.options = res3.data.data;
                    })
                );
        },

        getStudy(num) {
            var a = '';
            switch (num) {
                case 1:
                    a = '初一';
                    break;
                case 2:
                    a = '初二';
                    break;
                case 3:
                    a = '初三';
                    break;
                case 4:
                    a = '四年级';
                    break;
                case 5:
                    a = '五年级';
                    break;
                case 6:
                    a = '六年级';
                    break;
                case 7:
                    a = '高一';
                    break;
                case 8:
                    a = '高二';
                    break;
                case 9:
                    a = '高三';
                    break;
                case 10:
                    a = '中一';
                    break;
                case 11:
                    a = '中二';
                    break;
                case 12:
                    a = '中三';
                    break;

                case 13:
                    a = '中专毕业';
                    break;
                case 14:
                    a = '大一';
                    break;
                case 15:
                    a = '大二';
                    break;
                case 16:
                    a = '大三';
                    break;
                case 17:
                    a = '未读';
                    break;
            }
            return a;
        },

    },
};
</script>
<style lang="scss" scoped>
.buttonText {
    padding: 0 0 0 6px;
    color: #888;
}

.more_top {
    text-align: right;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
    background: #fff;
    padding: 10px 35px 10px 0;
    .fanhui {
        font-size: 16px;
        color: #666;
        display: inline-block;
        margin-left: 30px;
        margin-right: 20px;
        i {
            margin-right: 5px;
            color: #000;
        }
        &:hover {
            cursor: pointer;
        }
    }
}

.content {
    .left-head-img {
        float: left;
        margin-left: 40px;
        margin-top: 20px;

        img {
            border-radius: 50%;
        }

        margin-right: 20px;
    }

    .right-content {
        overflow: hidden;
        margin-right: 20px;
        p {
            display: block;
            width: 100%;

            i {
                font-size: 22px;
            }

            span {
                font-size: 18px;
                b {
                    display: inline-block;
                    margin-right: 10px;
                    width: 5px;
                    height: 18px;
                    border-radius: 5px;
                    background: #0055e9;
                }
            }
        }

        .right_content_top {
            height: 420px;
            background: #ffffff;
            box-shadow: 2px 2px 8px 0px #efefef;
            border-radius: 2px;
            margin: 10px;
            padding: 20px 0 0 20px;
            .item_con {
                width: 90%;
                margin-left: 10%;
            }
        }

        .right_content_bot {
            height: 160px;
            background: #ffffff;
            box-shadow: 2px 2px 8px 0px #efefef;
            border-radius: 2px;
            margin: 30px 10px 10px 10px;
            padding: 20px 0 0 20px;
            .item_con {
                width: 90%;
                margin-left: 10%;
            }
        }

        .item {
            width: 33.333%;
            float: left;
            margin-top: 30px;
            font-weight: 400;

            div:nth-child(1) {
                width: 9px;
                height: 9px;
                border-radius: 50%;
                margin-right: 14px;
                background: #fff;
                float: left;
                margin-top: 6px;
                font-weight: 400;
                color: #333;
            }

            div:nth-child(2) {
                float: left;
                color: #666;
                font-weight: 400;
                font-size: 16px;
                white-space: nowrap;

                text-overflow: ellipsis;

                overflow: hidden;
            }

            div:nth-child(3) {
                float: left;
                color: #666;
                font-weight: 400;
                font-size: 16px;
                white-space: nowrap;

                text-overflow: ellipsis;

                overflow: hidden;
            }
        }
    }
}

.dialog-wrap {
    & > div:nth-child(1) {
        width: 35%;
        display: inline-block;
        float: left;

        h4 {
            font-size: 24px;
            color: #626262;
            // border-bottom: 1px dashed #979797;
            padding: 0 0 10px 0;
            position: relative;
            width: 74%;
            margin: 0 auto;
            margin-bottom: 20px;
        }

        h4::after {
            border: 0px solid #fff; // 主要是这句
            background: transparent;
            display: block;
            content: " ";
            // height: 100%;
            // width: 100%;
            position: absolute;
            top: 100%;
            left: 53%;
            transform: translateX(-50%) translateY(-0%);
            width: 100%;
            height: 100%;
            background-image: linear-gradient(
                to right,
                #ccc 0%,
                #979797 40%,
                transparent 50%
            );
            background-size: 8px 1px;
            background-repeat: repeat-x;
        }

        p {
            color: #b8c4ce;
            font-size: 16px;
            line-height: 30px;
            margin: 10px 0;
        }

        button {
            border: 1px solid #4bb3ff;
            color: #4bb3ff;
        }

        .upload-wrap {
            width: 100%;
            height: 40px;
            position: relative;
            text-align: center;
            cursor: pointer;
            overflow: hidden;

            span {
                z-index: 1;
                line-height: 40px;
                color: #4bb3ff;
                font-size: 18px;
            }

            input {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
                z-index: 2;
            }
        }
    }

    & > div:nth-child(2) {
        width: 55%;
        display: inline-block;
        position: relative;
        margin-left: 60px;
        z-index: 2;
    }

    & > div:nth-child(2)::after {
        border: 1px solid #fff; // 主要是这句
        background: transparent;
        display: block;
        content: " ";
        // height: 100%;
        // width: 100%;
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 45%;
        transform: translateX(-50%) translateY(-50%);
        width: 100%;
        height: 100%;
        background-image: linear-gradient(
            to bottom,
            #ccc 0%,
            #e3e3e3 30%,
            transparent 50%
        );
        background-size: 1px 8px;
        background-repeat: repeat-y;
    }
}

.el-form-item__content .el-button:nth-child(1) {
    margin: 0;
}

.deit_btn {
    background: #1a9f16;
    color: #fff;
    width: 120px;
}
._yic {
    width: 260px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
}
</style>